import ApiService from "../ApiService";
import StorageService from "../StorageService";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import React from "react";

const AuthenticationService = {
  isUserLoggedIn() {
    if (
      StorageService.getData("access_token") &&
      StorageService.getData("access_token") !== ""
    )
      return true;
    else return false;
  },
  isCampaignActive(campaign_url, event_url) {
    let getParams = {
      campaign_url: campaign_url,
      event_url: event_url,
    };
    return new Promise((resolve, reject) => {
      ApiService.getData("checkIfCampaignActive", getParams, true).then(
        (response) => {
          if (response) {
            console.log(response.data); return;
            if (response.data.status == false) {
              if (response.data.message == "expired") {
                window.location.href =
                  "/" + event_url + "/" + campaign_url + "/expired";
              }
              else if (response.data.message == "invalid") {
                window.location.href =
                  "/" + event_url + "/" + campaign_url + "/invalid";
              }

              // return "false";
            }
          }
        }
      );
    }).catch((err) => console.log(err));
  },
};

export default AuthenticationService;
